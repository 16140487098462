<template>
  <!--  :show-create-button="$auth.hasRight('roles__edit_information')"-->
  <div>
    <template-list
      :model="$userPromocode"
      :show-create-button="false"
      header-classes="user-promocodes-list-header"
    >
      <template v-slot:actions>
        <!--        v-if="-->
        <!--        $promocodeCondition.item?.type === $promocodeCondition.typeEnum.TEXT-->
        <!--        "-->
        <v-spacer />
        <v-btn depressed class="rounded-lg mr-3" color="primary">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="rounded-lg"
          color="primary"
          @click="openNewPromocodesModal()"
        >
          <div class="text-none">Выпустить промокоды</div>
        </v-btn>
      </template>
      <template v-slot="{ object, to, index }">
        <v-col class="pa-0 body-2">
          <v-row
            no-gutters
            align="center"
            class="font-weight-bold"
            v-if="
              $promocodeCondition.item?.type ===
              $promocodeCondition.typeEnum.TEXT
            "
          >
            <div>Текст: {{ object.text }}</div>
            <v-spacer></v-spacer>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>
              Пользователь:
              <component
                :is="object.user_data ? 'router-link' : 'div'"
                :to="
                  object.user_data
                    ? { name: 'userItem', params: { userId: object.user_id } }
                    : undefined
                "
              >
                {{ object.user_data?.phone || "Отсуствует" }}
              </component>
            </div>
          </v-row>
          <v-row
            no-gutters
            align="center"
            class="mt-0 text-body-2"
            v-if="object.received_by_order_id"
          >
            <div>
              Получен за заказ:
              <span
                style="cursor: pointer"
                :class="{ 'text-primary': !!object.received_by_order_id }"
                @click="openOrder(object.received_by_order_id)"
              >
                {{ object.received_by_order_id || "Без заказа" }}
              </span>
            </div>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>Когда получен: {{ parseDate(object.created_at) }}</div>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>
              Потрачен на заказ:
              <span
                style="cursor: pointer"
                :class="{ 'text-primary': !!object.spent_on_order_id }"
                @click="openOrder(object.spent_on_order_id)"
              >
                {{ object.spent_on_order_data?.daily_number }}
              </span>
            </div>
          </v-row>
          <v-row
            no-gutters
            align="center"
            class="mt-0 text-body-2"
            v-if="object.spent_at"
          >
            <div>Когда потрачен: {{ parseDate(object.spent_at) }}</div>
          </v-row>
          <v-row
            no-gutters
            align="center"
            class="mt-0 text-body-2"
            v-if="object.progress"
          >
            <div>Прогресс: {{ object.progress }}</div>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>
              Истекает:
              {{ object.expired_at ? parseDate(object.expired_at) : "Никогда" }}
            </div>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>ID: {{ object.id }}</div>
          </v-row>
        </v-col>
      </template>
    </template-list>
    <CreateTextCodesModal v-model="createTextCodesModelValue" />
    <CreateUserPromocodeModal v-model="createUserPromocodesModelValue"/>
    <OrderViewDialog :value="orderModal" @input="orderModal = $event" />
  </div>
</template>

<script>
import CreateTextCodesModal from "@/admin/views/promocodes/CreateTextCodesModal.vue";
import { parseDate } from "../../../services/parseDate";
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog.vue";
import CreateUserPromocodeModal from "@/admin/views/promocodes/CreateUserPromocodeModal.vue";

export default {
  name: "UserPromocodeList",
  components: { OrderViewDialog, CreateTextCodesModal, CreateUserPromocodeModal },
  data: () => {
    return {
      createTextCodesModelValue: false,
      createUserPromocodesModelValue: false,
      orderModal: false,
    };
  },
  created() {
    const id = this.$route.params.promocodeConditionId;
    if (!id) return;
    this.$userPromocode.filters.promocode_condition = id;
    void this.$userPromocode.loadList();
  },
  methods: {
    parseDate,
    openOrder(id) {
      if (!id) return;
      this.$order.loadItem(id).then(() => {
        this.orderModal = true;
      });
    },
    openNewPromocodesModal() {
      const item = this.$promocodeCondition.item
      if (!item) return
      if (item.type === this.$promocodeCondition.typeEnum.DEFAULT) this.createUserPromocodesModelValue = true
      else this.createTextCodesModelValue = true
    }
  },
};
</script>

<style scoped>
.text-primary {
  color: #e5a400;
}

:deep(.user-promocodes-list-header) {
  position: absolute;
  right: 0;
  transform: translateY(-68px);
}
</style>
