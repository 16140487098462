import Vue from "vue";
import store from "./store/store";

import {Ingredient} from "@/models/ingredientAdmin";

Vue.prototype.$auth = Vue.observable(require('../airModels/auth'))
Vue.prototype.$ingredient = Vue.set(store.state.items, 'ingredient', new Ingredient())
Vue.prototype.$productGroup = Vue.observable(require('../airModels/productGroup'))
Vue.prototype.$equipmentConfig = Vue.observable(require('../airModels/equipmentConfig'))
Vue.prototype.$ingredientOutletDetail = Vue.observable(require('../airModels/ingredientOutletDetail'))
Vue.prototype.$productIngredientGroup = Vue.observable(require('../airModels/productIngredientGroup'))
Vue.prototype.$productIngredientGroupIngredient = Vue.observable(require('../airModels/productIngredientGroupIngredient'))
Vue.prototype.$outlet = Vue.observable(require('../airModels/outlet'))
Vue.prototype.$checkout = Vue.observable(require('../airModels/checkout'))
Vue.prototype.$outletServiceLog = Vue.observable(require('../airModels/outletServiceLog'))
Vue.prototype.$product = Vue.observable(require('../airModels/product'))
Vue.prototype.$promo = Vue.observable(require('../airModels/promo'))
Vue.prototype.$order = Vue.observable(require('../airModels/order'))
Vue.prototype.$equipmentLockedPeriod = Vue.observable(require('../airModels/equipmentLockedPeriod'))
Vue.prototype.$user = Vue.observable(require('../airModels/user'))
Vue.prototype.$userNotification = Vue.observable(require('../airModels/userNotification'))
Vue.prototype.$equipment = Vue.observable(require('../airModels/equipment'))
Vue.prototype.$deviceActivity = Vue.observable(require('../airModels/deviceActivity'))
Vue.prototype.$company = Vue.observable(require('../airModels/company'))
Vue.prototype.$displayDevice = Vue.observable(require('../airModels/display/displayDevice'))
Vue.prototype.$displayLine = Vue.observable(require('../airModels/display/displayLine'))
Vue.prototype.$displayDeviceGroup = Vue.observable(require('../airModels/display/displayDeviceGroup'))
Vue.prototype.$displayContent = Vue.observable(require('../airModels/display/displayContent'))
Vue.prototype.$websocketEvent = Vue.observable(require('../airModels/websocketEvent'))
Vue.prototype.$analytics = Vue.observable(require('../airModels/analytics'))
Vue.prototype.$nomenclature = Vue.observable(require('../airModels/nomenclature'))
Vue.prototype.$ingredientNomenclature = Vue.observable(require('../airModels/ingredientNomenclature'))
Vue.prototype.$deliveryArea = Vue.observable(require('../airModels/deliveryArea'))
Vue.prototype.$productOutlet = Vue.observable(require('../airModels/productOutlets'))
Vue.prototype.$equipmentConfigService = Vue.observable(require('../airModels/equipmentConfigService'))
Vue.prototype.$productTag = Vue.observable(require('../airModels/productTag'))
Vue.prototype.$airIngredient = Vue.observable(require('../airModels/ingredient'))
Vue.prototype.$outletNomenclature = Vue.observable(require('../airModels/outletNomenclature'))
Vue.prototype.$line = Vue.observable(require('../airModels/line'))
Vue.prototype.$lineProductGroup = Vue.observable(require('../airModels/lineProductGroup'))
Vue.prototype.$companyBank = Vue.observable(require('../airModels/companyBank'))
Vue.prototype.$outletLine = Vue.observable(require('../airModels/outletLine'))
Vue.prototype.$ingredientGroup = Vue.observable(require('../airModels/ingredientGroup'))
Vue.prototype.$ingredientImage = Vue.observable(require('../airModels/ingredientImage'))
Vue.prototype.$userRole = Vue.observable(require('../airModels/userRole'))
Vue.prototype.$balanceOperation = Vue.observable(require('../airModels/balanceOperation'))
Vue.prototype.$discountSystem = Vue.observable(require('../airModels/discountSystem'))
Vue.prototype.$settings = Vue.observable(require('../airModels/settings'))
Vue.prototype.$reason = Vue.observable(require('../airModels/reason'))
Vue.prototype.$shift = Vue.observable(require('../airModels/shift'))
Vue.prototype.$answerTemplate = Vue.observable(require('../airModels/answerTemplate'))
Vue.prototype.$orderReview = Vue.observable(require('../airModels/orderReview'))
Vue.prototype.$ticketMessage = Vue.observable(require('../airModels/ticketMessage'))
Vue.prototype.$document = Vue.observable(require('../airModels/document'))
Vue.prototype.$userRolePushSetting = Vue.observable(require('../airModels/userRolePushSetting'))
Vue.prototype.$notification = Vue.observable(require('../airModels/notification'))
Vue.prototype.$productIngredientGroupOutlet = Vue.observable(require('../airModels/productIngredientGroupOutlet'))
Vue.prototype.$productIngredientGroupIngredientOutlet = Vue.observable(require('../airModels/productIngredientGroupIngredientOutlet'))
Vue.prototype.$trackHistory = Vue.observable(require('../airModels/trackHistory'))
Vue.prototype.$afterOrderReview = Vue.observable(require('../airModels/afterOrderReview'))
Vue.prototype.$orderReviewTag = Vue.observable(require('../airModels/orderReviewTag'))
Vue.prototype.$orderReviewTagGroup = Vue.observable(require('../airModels/orderReviewTagGroup'))
Vue.prototype.$ingredientPriceListEdict = Vue.observable(require('../airModels/ingredientPriceListEdict'))
Vue.prototype.$cookStation = Vue.observable(require('../airModels/cookStation'))
Vue.prototype.$productBlank = Vue.observable(require('../airModels/productBlank'))
Vue.prototype.$promocodeCondition = Vue.observable(require('../airModels/promocodeCondition'))
Vue.prototype.$userPromocode = Vue.observable(require('../airModels/userPromocode'))