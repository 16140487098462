<template>
  <div class="px-1 mt-10">
    <v-row align="center" сlass="ma-0" style="margin-left: 0; margin-right: 0">
      <div class="text-h6 mr-3">Награда</div>
      <v-divider />
    </v-row>
    <v-select
      v-model="selectedRewardType"
      @input="updateReward($event)"
      class="mb-3 mt-5 rounded-lg"
      dense
      hide-details
      label="Тип награды"
      outlined
      :items="$promocodeCondition.rewardTypeList"
      item-text="label"
      item-value="value"
      :rules="[rules.required]"
      :disabled="disabled"
    />
    <v-text-field
      v-if="rewardInputField"
      v-model="item.reward[rewardInputField]"
      :label="rewardInputFieldLabel"
      class="mb-3 mt-5 rounded-lg"
      dense
      hide-details
      outlined
      :rules="[rules.required]"
      type="number"
      :disabled="disabled"
    ></v-text-field>
    <template
      v-if="
        [
          $promocodeCondition.rewardTypes.FREE_PRODUCTS,
          $promocodeCondition.rewardTypes.PRODUCTS_DISCOUNT,
        ].includes(selectedRewardType)
      "
    >
      <v-btn depressed @click="productsSelectorDialog = true">Выбрать продукты</v-btn>
<!--            {{ item?.reward }}-->
      <ProductsSelector
        v-model="productsSelectorDialog"
        @updateProductsData="updateProductsStructure($event)"
        :products-data="item?.reward?.products_data"
        :menu="productsStructure"
        :with-discount-input="selectedRewardType !== $promocodeCondition.rewardTypes.FREE_PRODUCTS"
        :disabled="disabled"
      />
    </template>
  </div>
</template>

<script>
import rules from "air-vue-model/rules";
import ProductsSelector from "@/admin/views/promocodes/coditionComoponents/ProductsSelector.vue";
import {cloneDeep} from "lodash";

export default {
  name: "Reward",
  components: { ProductsSelector },
  computed: {
    rules() {
      return rules;
    },
  },
  props: {
    item: Object,
    disabled: Boolean,
  },
  data: () => {
    return {
      selectedRewardType: null,
      rewardInputField: null,
      rewardInputFieldLabel: null,
      productsSelectorDialog: false,
      menuStructure: [],
      productsStructure: [],

      // productsLoading: false,
    };
  },
  mounted() {
    // if (!this.$product.list.length) {
    // this.productsLoading = true;
    void this.$product
      .sendGetSingle("structure", undefined, {
        active: true,
        show_in_app: true,
      })
      .then((res) => {
        this.productsStructure = res.result;
        // this.productsLoading = false;
        // this.setMenuStructure();
      });
    // }
    if (!this.item) return;
    this.selectedRewardType = this.$promocodeCondition.getRewardType(this.item);
    this.updateReward(this.selectedRewardType, false);
  },
  methods: {
    updateReward(newType, updateRewardField = true) {
      const types = this.$promocodeCondition.rewardTypes;
      if (newType === types.INCREASE_BONUS) {
        if (updateRewardField) this.item.reward = { increase_bonus: null };
        this.rewardInputField = "increase_bonus";
        this.rewardInputFieldLabel = "Укажите сколько бонусов будет начислено";
      }
      if (newType === types.INCREASE_BONUS_PERCENT) {
        if (updateRewardField)
          this.item.reward = { increase_bonus_percent: null };
        this.rewardInputField = "increase_bonus_percent";
        this.rewardInputFieldLabel =
          "Укажите какой % от суммы заказа будет начислен бонусами";
      }
      if (newType === types.FREE_DELIVERY) {
        if (updateRewardField)
          this.item.reward = { delivery_discount_percent: 100 };
        this.rewardInputField = null;
        this.rewardInputFieldLabel = null;
      }
      if (newType === types.DELIVERY_DISCOUNT) {
        if (updateRewardField)
          this.item.reward = { delivery_discount_percent: null };
        this.rewardInputField = "delivery_discount_percent";
        this.rewardInputFieldLabel = "Укажите % скидки на доставку";
      }
      if (newType === types.PRODUCTS_DISCOUNT) {
        if (updateRewardField)
          this.item.reward = {
            products_data: [{ products: [], discount_percent: 0 }],
          };
        this.rewardInputField = null;
        this.rewardInputFieldLabel = null;
      }
      if (newType === types.FREE_PRODUCTS) {
        if (updateRewardField)
          this.item.reward = {
            products_data: [{ products: [], discount_percent: 100 }],
          };
        this.rewardInputField = null;
        this.rewardInputFieldLabel = null;
      }
    },
    updateProductsStructure(data) {
      console.log('current', this.item)
      console.log('updateProductsStructure', cloneDeep(data))
      this.item.reward = {products_data: data}
    },
  },
};
</script>

<style scoped></style>
