<template>
  <div>
    <v-text-field
      v-model="object.name"
      class="mb-3 rounded-lg"
      dense
      hide-details
      label="Наименование"
      outlined
      :rules="[rules.required]"
    ></v-text-field>
    <v-text-field
      :value="object.description"
      @input="object.description = $event || null"
      class="mb-3 rounded-lg"
      dense
      hide-details
      label="Описание"
      outlined
    ></v-text-field>
    <v-select
      v-model="object.type"
      @input="onTypeChange"
      class="mb-3 rounded-lg"
      dense
      hide-details
      label="Тип"
      outlined
      :items="$promocodeCondition.typesList"
      item-text="label"
      item-value="value"
      :disabled="isDeepUpdateLocked"
    />
    <!--    todo ограничивать типы -->
    <v-select
      v-model="object.status"
      class="mb-3 rounded-lg"
      dense
      hide-details
      label="Статус"
      outlined
      :items="availableStatuses"
      item-text="label"
      item-value="value"
    />
    <date-picker
      v-model="object.start_date"
      label="Дата начала"
      with-time
      class="mb-3 rounded-lg"
      :disabled="isDeepUpdateLocked"
    ></date-picker>
    <date-picker
      v-model="object.end_date"
      label="Дата конца"
      with-time
      class="mb-3 rounded-lg"
      :rules="[rules.required]"
      :disabled="isDeepUpdateLocked"
    ></date-picker>
    <v-text-field
      :value="object.user_promocode_shelf_life"
      @input="object.user_promocode_shelf_life = Number($event) || null"
      class="mb-3 rounded-lg"
      dense
      type="number"
      hide-details
      label="Срок действия промокода после получения пользователем (дни)"
      outlined
      :disabled="isDeepUpdateLocked"
    ></v-text-field>
    <v-text-field
      :value="object.user_earn_limit"
      @input="object.user_earn_limit = Number($event) || null"
      class="rounded-lg"
      dense
      type="number"
      hide-details
      label="Сколько промокодов может получить пользователь"
      outlined
      :rules="[rules.required]"
      :disabled="isDeepUpdateLocked"
    ></v-text-field>
    <PromocodeConditionItemImages
      :item="object"
      ref="promocodeConditionItemImagesRef"
      @inited="onPromocodeConditionItemImagesInited()"
    />
    <!--  todo доработать products selector, чтобы он возвращал норм структуру   -->
    <ForEarn
      :item="object"
      v-if="object.type === $promocodeCondition.typeEnum.DEFAULT"
      :disabled="isDeepUpdateLocked"
    />
    <ForApply :item="object" :disabled="isDeepUpdateLocked" />
    <Reward :item="object" :disabled="isDeepUpdateLocked" />
  </div>
</template>

<script>
import Reward from "@/admin/views/promocodes/coditionComoponents/Reward.vue";
import ForApply from "@/admin/views/promocodes/coditionComoponents/ForApply.vue";
import ForEarn from "@/admin/views/promocodes/coditionComoponents/ForEarn.vue";
import { cloneDeep } from "lodash";
import rules from "air-vue-model/rules";
import PromocodeConditionItemImages from "@/admin/views/promocodes/PromocodeConditionItemImages.vue";

export default {
  name: "PromocodeConditionItem",
  components: { Reward, ForApply, ForEarn, PromocodeConditionItemImages },
  data: () => ({}),
  methods: {
    onPromocodeConditionItemImagesInited() {
      this.$eventBus.$emit("promocodeConditionItemInited", this);
    },
    onTypeChange(newType) {
      if (
        newType === this.$promocodeCondition.typeEnum.TEXT &&
        this.$promocodeCondition.item
      ) {
        this.$promocodeCondition.item.for_apply.apply_limit = 1;
        this.$promocodeCondition.item.for_earn = cloneDeep(
          this.$promocodeCondition.default.for_earn
        );
      }
    },
    beforeItemSave(item) { // НЕ УДАЛЯТЬ
      if (!item) return
      const isBase64 = (v) =>
        typeof v === "string" && v.startsWith("data:") && v.includes("base64");
      const imageFields = ["image", "first_icon", "last_icon"];
      for (const key of imageFields) {
        if (isBase64(item[key])) item[key] = undefined;
      }
    },
    afterSave(_) {
      this.$refs.promocodeConditionItemImagesRef.afterSave(_);
    },
  },
  mounted() {},
  computed: {
    rules() {
      return rules;
    },
    object() {
      const item = this.$promocodeCondition.item;
      if (item.for_earn === null) item.for_earn = {};
      if (item.for_apply === null) item.for_apply = {};
      return item;
    },
    isDeepUpdateLocked() {
      return (
        this.object.id &&
        this.object.status !== this.$promocodeCondition.statusEnum.DRAFT
      );
    },
    availableStatuses() {
      const model = this.$promocodeCondition;
      if (this.isDeepUpdateLocked)
        return model.statusList.filter(
          (v) => v.value !== model.statusEnum.DRAFT
        );
      return model.statusList;
    },
  },
};
</script>

<style scoped></style>
