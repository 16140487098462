<template>
  <v-navigation-drawer
    v-model="$store.state.drawer"
    clipped
    app
    style="height: 100dvh"
  >
    <v-list
      v-for="(group, groupIndex) in groups"
      v-if="currentValues(group).length > 0"
      :key="groupIndex"
      class="pa-1"
      dense
      nav
    >
      <v-subheader>{{ group.name }}</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in currentValues(group)"
          :key="i"
          :to="{
            name: item.to,
          }"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  data: () => ({
    groups: [
      {
        name: "Компании",
        items: [
          {
            icon: "mdi-domain",
            text: "Компании",
            to: "companyList",
            right: "companies__access",
          },
          {
            icon: "mdi-store",
            text: "Торговые точки",
            to: "outletList",
            right: "outlets__access",
          },
          {
            icon: "mdi-mailbox-open-outline",
            text: "Кассы",
            to: "checkoutList",
            right: "checkouts__access",
          },
          {
            icon: "mdi-chart-timeline-variant",
            text: "Аналитика",
            to: "analytics",
            right: "analytics__access",
          },
          {
            icon: "mdi-fire-circle",
            text: "Тепловая карта",
            to: "heatmap",
            right: "orders__heatmap",
          },
        ],
      },
      {
        name: "Пользователи",
        items: [
          {
            icon: "mdi-account",
            text: "Пользователи",
            to: "userList",
            right: "users__access",
          },
          {
            icon: "mdi-calendar-account",
            text: "Роли",
            to: "userRoleList",
            right: "user_roles__access",
          },
          {
            icon: "mdi-file-document-outline",
            text: "Заказы",
            to: "orderList",
            right: "orders__access",
          },
          {
            icon: "mdi-message-star-outline",
            text: "Отзывы",
            to: "orderReviewList",
            right: "service_app__order_reviews_access",
          },
        ],
      },
      {
        name: "Блюда",
        items: [
          {
            icon: "mdi-food-fork-drink",
            text: "Группы блюд",
            to: "productGroupList",
            right: "products__access",
          },
          {
            icon: "mdi-sitemap",
            text: "Номенклатура",
            to: "nomenclatureList",
            right: "nomenclature__access",
          },
          {
            icon: "mdi-format-list-group-plus",
            text: "Модификаторы",
            to: "ingredientGroupList",
            right: "products__access",
          },
          {
            icon: "mdi-currency-usd",
            text: "Прайс листы",
            to: "ingredientPriceListEdictList",
            right: "price_list__access",
          },
          {
            icon: "mdi-checkbox-blank-badge-outline",
            text: "Заготовки",
            to: "productBlankList",
            right: "price_list__access",
          },
        ],
      },
      {
        name: "Система лояльности",
        items: [
          {
            icon: "mdi-cart-percent",
            text: "Система скидок",
            to: "discountSystemItem",
            right: "discount_systems__access",
          },
          {
            icon: "mdi-wallet",
            text: "Операции",
            to: "balanceOperationList",
            right: "balance__access",
          },
          {
            icon: "mdi-sale",
            text: "Акции",
            to: "promoList",
            right: "promo__access",
          },
          {
            icon: "mdi-ticket-percent",
            text: "Промокоды",
            to: "promocodeConditionList",
            right: "", // todo добавить право
          },
          {
            icon: "mdi-monitor",
            text: "Настройки экранов",
            to: "displayHome",
            right: "displays__access",
          },
        ],
      },
      {
        name: "Трекер",
        items: [
          {
            icon: "mdi-minus",
            text: "Линии",
            to: "lineList",
            right: "tracker__access",
          },
          {
            icon: "mdi-format-list-group",
            text: "Группы товаров для линий",
            to: "lineProductGroupList",
            right: "tracker__access",
          },
          {
            icon: "mdi-cog",
            text: "Оборудование",
            to: "equipmentList",
            // right: "answer_template__access",
          },
        ],
      },
      {
        name: "Доставка",
        items: [
          {
            icon: "mdi-truck-delivery",
            text: "Администрирование",
            to: "deliveryAdmin",
            right: "delivery_loop__access",
          },
          {
            icon: "mdi-account-hard-hat",
            text: "Смены",
            to: "userCouriers",
            right: "delivery_loop__access",
          },
        ],
      },
      {
        name: "Справочники",
        items: [
          {
            icon: "mdi-format-list-checks",
            text: "Список причин",
            to: "reasonList",
            right: "reason__access",
          },
          {
            icon: "mdi-frequently-asked-questions",
            text: "Шаблоны для ответа",
            to: "answerTemplateList",
            right: "answer_template__access",
          },
          {
            icon: "mdi-message-plus-outline",
            text: "Категории отзывов",
            to: "orderReviewTagList",
            right: "answer_template__access",
          },
          // {
          //   icon: "mdi-account-hard-hat",
          //   text: "Смены",
          //   to: "shiftList",
          //   right: "",
          // },
        ],
      },
      {
        name: "Сервисные функции",
        items: [
          // {
          //     icon: 'mdi-access-point',
          //     text: 'Сервисный лог',
          //     to: 'outletServiceLogList',
          //     right: 'service_logs__access'
          // },
          {
            icon: "mdi-clock-time-eight",
            text: "Блокировка оборудования",
            to: "equipmentLockedPeriodList",
            right: "equipment_locked_periods__access",
          },
          {
            icon: "mdi-antenna",
            text: "Активность устройств",
            to: "deviceActivityList",
            right: "device_activities__access",
          },
          {
            icon: "mdi-table-remove",
            text: "Стоп-лист",
            to: "blockedProductsTable",
            right: "outlets__stop_list",
          },
          {
            icon: "mdi-database-check",
            text: "Данные базы",
            to: "databaseData",
            right: "products__database",
          },
          // {
          //     icon: 'mdi-chart-timeline',
          //     text: 'Диаграмма загруженности',
          //     to: 'equipmentLockedPeriodGant',
          // },
          {
            icon: "mdi-semantic-web",
            text: "Подключение к вебсокету",
            to: "websocket",
            right: "outlets__websocket_connection",
          },
          {
            icon: "mdi-send-clock",
            text: "Вебсокет события",
            to: "websocketEventList",
            right: "websocket_events__access",
          },
          {
            icon: "mdi-cog",
            text: "Настройки",
            to: "settingsBase",
            right: "superuser",
          },
        ],
      },
    ],
  }),
    methods: {
        currentValues(group) {
            return group.items.filter((v) => this.$auth.hasRight(v.right));
        },
    },
    computed: {},
};
</script>
