<template>
    <div style="width: min-content">
        <div class="mb-3 body-2" v-if="title">{{ title }}</div>
        <v-hover v-slot="{hover}">
            <div style="position: relative; width: 100%; height: 100%;">
                <v-img
                    style="border: 1px solid #c5c5c5"
                    @click="image ? openImage() : uploadImage()" class="cursor-pointer rounded"
                    :src="image || '/images/not-found.png'"
                    :max-height="height" :width="width" :height="height" :aspect-ratio="aspectRatio"
                    :max-width="width">
                    <div v-if="hover" class="image-box d-flex align-center justify-center">
                        <v-icon color="white" :size="width > height ? height / 3 : width / 3">
                            {{ image ? 'mdi-eye' : 'mdi-pen' }}
                        </v-icon>
                    </div>
                </v-img>
                <div style="z-index: 100; position: absolute; top: 10px; right: 10px">
                    <v-icon v-if="hover && !disableEdit && image" :size="25"
                            color="white" @click="uploadImage">
                        mdi-pen
                    </v-icon>
                    <v-icon v-if="hover && canDelete" :size="25" class="ml-3"
                            color="white" @click="$emit('delete')">
                        mdi-delete
                    </v-icon>
                </div>
            </div>
            <!--            <v-img @click="uploadImage" class="cursor-pointer rounded" :src="image || '/images/not-found.png'"-->
            <!--                   :max-height="height" :width="width" :height="height" :aspect-ratio="aspectRatio" :max-width="width">-->
            <!--                <v-icon v-if="hover" :size="30" style="z-index: 10; position: absolute; top: 0; left: 0"-->
            <!--                        color="red darken-2" @click="$emit('delete')">-->
            <!--                    mdi-delete-->
            <!--                </v-icon>-->
            <!--                <div v-if="hover" class="image-box d-flex align-center justify-center">-->
            <!--                    <v-icon color="white" :size="width > height ? height / 2.5 : width / 2.5">mdi-image-edit</v-icon>-->
            <!--                </div>-->
            <!--            </v-img>-->
        </v-hover>
    </div>
</template>

<script>
export default {
    name: 'ImageUploader',
    props: {
        image: String,
        title: String,
        width: {
            type: [Number, String],
            default: 140
        },
        height: {
            type: [Number, String],
            default: 140
        },
        aspectRatio: {
            type: [Number, String],
            default: 1
        },
        disableEdit: Boolean,
        canDelete: Boolean
    },
    data: () => ({}),
    methods: {
        openImage() {
            this.$eventBus.$emit('setImages', [{image: this.image}])
            this.$eventBus.$emit('setIndex', 0)
        },
        uploadImage() {
            const input = document.createElement('input')
            input.setAttribute('hidden', 'true')
            input.setAttribute('type', 'file')
            input.setAttribute('id', 'upload-company-image')
            input.setAttribute('accept', 'image/jpeg, image/png')
            document.body.appendChild(input)
            input.click()
            input.addEventListener('change', (e) => {
                const input = e.target
                if (input && input.files && input.files.length) {
                    const file = input.files[0]
                    this.$emit('input', file)
                    input.remove()
                } else if (input) {
                    input.remove()
                }
            })
        }
    }
}
</script>

<style scoped>

</style>