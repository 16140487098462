<template>
  <v-dialog :value="value" @input="closeModal()">
    <v-card class="pa-0 px-3" :class="{ 'pt-3': !withDiscountInput }">
      <v-form ref="form" v-model="form">
        <!--        {{ currentData.products }}-->
        <v-text-field
          v-if="withDiscountInput"
          dense
          hide-details
          class="py-3 rounded-lg"
          outlined
          type="number"
          v-model.number="currentData.discount_percent"
          label="Введите % скидки"
          :rules="[rules.required]"
          :disabled="disabled"
        />
        <v-expansion-panels v-if="value" accordion focusable>
          <v-expansion-panel v-for="(group, index) in menu" :key="index">
            <v-expansion-panel-header v-if="currentData.products">
              <v-row class="ma-0" align="center">
                <v-checkbox
                  :input-value="!!findGroup(group.id)"
                  readonly
                  :indeterminate="isIndeterminateGroupCheckbox(group.id)"
                  @click.stop="disabled ? void 0 : toggleGroup(group.id)"
                  hide-details
                  class="ma-0"
                  :disabled="disabled"
                />
                <b class="mt-2">{{ group.name }}</b>
                <div class="ml-3 text-body-2" style="margin-top: 8px">
                  {{ groupStatusText(group) }}
                </div>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-2">
              <v-row
                class="ma-0"
                v-for="(product, index) in group.items"
                :key="product.id"
                :class="{ 'mt-3': index }"
              >
                <v-checkbox
                  :input-value="isProductSelected(product)"
                  hide-details
                  readonly
                  class="ma-0"
                  @click="disabled ? void 0 : toggle(product, product.group)"
                  :indeterminate-icon="
                    indeterminateProductCheckboxIcon(product)
                  "
                  :indeterminate="isIndeterminateProductCheckbox(product)"
                  :color="indeterminateProductCheckboxIconColor(product)"
                  :disabled="disabled"
                />
                <v-row
                  no-gutters
                  align="center"
                  class="mt-1"
                  @click="openProduct(product)"
                  :style="{
                    cursor: !isProductExcluded(product) ? 'pointer' : '',
                  }"
                >
                  {{ product.name || product.vendor_name }}
                  <div class="ml-3 text-caption">
                    {{ productStatusText(product) }}
                  </div>
                </v-row>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
          color="primary"
          class="mt-5 mb-3"
          @click="closeModal()"
          :disabled="!form"
        >
          <div class="text-none">Применить</div>
        </v-btn>
      </v-form>
    </v-card>
    <ProductDialog
      v-model="productModal"
      :product="productToOpen"
      :selected-ingredient-ids="productSelectedIngredientIds"
      :excluded-ingredient-ids="productExcludedIngredientIds"
      :is-product-selected="isProductToOpenSelected"
      @updateIngredients="updateSelectedIngredients($event, productToOpen)"
      :disabled="disabled"
    />
  </v-dialog>
</template>

<script>
import { clone, cloneDeep } from "lodash";
import ProductDialog from "./ProductDialog.vue";
import rules from "air-vue-model/rules";

export default {
  name: "ProductsSelector",
  computed: {
    rules() {
      return rules;
    },
  },
  components: { ProductDialog },
  data: () => {
    return {
      currentData: { products: [] },
      form: null,

      productToOpen: null,
      productModal: false,
      isProductToOpenSelected: false,
      productSelectedIngredientIds: [],
      productExcludedIngredientIds: [],
    };
  },
  props: {
    value: Boolean,
    productsData: Array, // [{products: []}]
    withDiscountInput: Boolean,
    menu: Array,
    disabled: Boolean,
  },
  emits: ["input", "updateProductsData"],
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("input", false);
      this.$emit("updateProductsData", [this.currentData]);
    },
    isProductFictiveSelected(product) {
      const group = this.findGroup(product.group);
      return !!(group && !group.children.length);
    },
    isProductSelected(product) {
      if (this.isProductFictiveSelected(product)) return true;
      const res = this.findProductInStructure(product.id);
      return !!res;
    },
    isProductExcluded(product) {
      const group = this.findGroup(product.group);
      if (!group) return false;
      if (group && group.excluded_children) {
        const found = group.excluded_children.find(
          (v) => v.product_id === product.id && v.type === "product"
        );
        if (found) return true;
      }
      return false;
    },
    isProductPartiallySelected(product) {
      const found = this.findProductInStructure(product.id);
      if (!found) return false;
      return !!(found.children.length || found.excluded_children.length);
    },
    isIndeterminateProductCheckbox(product) {
      if (this.isProductExcluded(product)) return true;
      const found = this.findProductInStructure(product.id);
      return !!(
        found &&
        (found.children.length || found.excluded_children.length)
      );
    },
    indeterminateProductCheckboxIcon(product) {
      if (this.isProductExcluded(product)) return "mdi-close";
      if (this.isProductPartiallySelected(product)) return undefined;
    },
    indeterminateProductCheckboxIconColor(product) {
      if (this.isProductExcluded(product)) return "red";
      if (this.isProductPartiallySelected(product)) return undefined;
    },
    toggleGroup(groupId) {
      const groupIndex = this.currentData.products.findIndex(
        (v) => v.product_id === groupId && v.type === "product_group"
      );
      if (groupIndex > -1) this.currentData.products.splice(groupIndex, 1);
      else
        this.currentData.products.push({
          product_id: groupId,
          type: "product_group",
          children: [],
          excluded_children: [],
        });
    },
    toggle(product, groupId) {
      if (this.isProductExcluded(product)) {
        const group = this.findGroup(product.group);
        if (!group) return;
        const index = group.excluded_children.findIndex(
          (v) => v.product_id === product.id && v.type === "product"
        );
        if (index > -1) group.excluded_children.splice(index, 1);
        return;
      }
      if (this.isProductFictiveSelected(product)) {
        const group = this.findGroup(product.group);
        if (!group) return;
        group.excluded_children.push({
          product_id: product.id,
          type: "product",
          children: [],
          excluded_children: [],
        });
        return;
      }
      if (this.isProductSelected(product)) {
        const index = this.currentData.products.findIndex(
          (v) => v.product_id === product.id && v.type === "product"
        );
        if (index > -1) {
          this.currentData.products.splice(index, 1);
          return;
        }
        const foundGroup = this.findGroupInStructure(groupId);
        if (foundGroup) {
          const index = foundGroup.children.findIndex(
            (v) => v.product_id === product.id
          );
          if (index > -1) {
            foundGroup.children.splice(index, 1);
            if (!foundGroup.children.length) {
              this.toggleGroup(groupId);
            }
          }
        }
      } else {
        const foundGroup = this.findGroupInStructure(groupId);
        const productData = {
          product_id: product.id,
          type: "product",
          children: [],
          excluded_children: [],
        };
        if (foundGroup) foundGroup.children.push(productData);
        else {
          this.currentData.products.push({
            type: "product_group",
            product_id: groupId,
            children: [productData],
            excluded_children: [],
          });
        }
      }
    },
    openProduct(product) {
      if (this.isProductExcluded(product)) return;
      void this.$product.loadItem(product.id).then((res) => {
        this.productToOpen = res;
        this.productModal = true;
        this.isProductToOpenSelected = this.isProductSelected(product);
        this.productSelectedIngredientIds =
          this.getSelectedIngredientIds(product);
        this.productExcludedIngredientIds =
          this.getExcludedIngredientIds(product);
      });
    },
    findGroup(id) {
      return this.currentData.products.find(
        (v) => v.product_id === id && v.type === "product_group"
      );
    },

    isIndeterminateGroupCheckbox(groupId) {
      const group = this.findGroup(groupId);
      if (!group) return false;
      if (group.children.length) return true;
      return !!group.excluded_children.length;
    },

    getSelectedIngredientIds(product) {
      const found = this.findProductInStructure(product.id);
      if (!found) return [];
      return found.children.map((v) => v.product_id);
    },

    getExcludedIngredientIds(product) {
      const found = this.findProductInStructure(product.id);
      if (!found) return [];
      return found.excluded_children.map((v) => v.product_id);
    },
    groupStatusText(group) {
      const groupData = this.findGroup(group.id);
      if (!groupData) return "";
      const selectedChildren = groupData.children.length;
      const excludedChildren = groupData.excluded_children.length;
      if (!selectedChildren && !excludedChildren) return "Выбрана вся группа";
      if (selectedChildren) return `Выбрано позиций: ${selectedChildren}`;
      if (excludedChildren)
        return `Выбрана вся группа, исключено позиций: ${excludedChildren}`;
    },

    productStatusText(product) {
      const productData = this.findProductInStructure(product.id);
      const groupData = this.findGroup(product.group);
      if (
        groupData &&
        !groupData.children.length &&
        !this.isProductExcluded(product)
      )
        return "Выбраны все модификаторы";
      if (!productData) return "";
      const selectedChildren = productData.children.length;
      const excludedChildren = productData.excluded_children.length;
      if (!selectedChildren && !excludedChildren)
        return "Задействованы все модификаторы";
      let text = "";
      if (selectedChildren)
        text += `Задействовано модификаторов: ${selectedChildren}`;
      if (excludedChildren) {
        let currentText = `Исключено модификаторов: ${excludedChildren}`;
        if (text) {
          text += ", ";
          currentText = currentText.toLowerCase();
        }
        text += currentText;
      }
      return text;
    },

    buildChildStructureFromIds(ids, type) {
      return ids.map((id) => {
        return {
          product_id: id,
          type,
          children: [],
          excluded_children: [],
        };
      });
    },
    updateSelectedIngredients(newSelectedIngredients, product) {
      const selected = this.buildChildStructureFromIds(
        newSelectedIngredients.selected
      );
      const excluded = this.buildChildStructureFromIds(
        newSelectedIngredients.excluded
      );
      let foundProduct = this.findProductInStructure(product.id, undefined);
      if (foundProduct) {
        foundProduct.children = selected;
        foundProduct.excluded_children = excluded;
        return;
      } else if (!selected.length && !excluded.length) return;

      foundProduct = {
        type: "product",
        product_id: product.id,
        children: selected,
        excluded_children: excluded,
      };
      let currentGroup = this.findGroupInStructure(product.group.id);
      if (currentGroup) {
        currentGroup.children.push(foundProduct);
        return;
      }

      currentGroup = {
        type: "product_group",
        product_id: product.group.id,
        children: [foundProduct],
        excluded_children: [],
      };
      this.currentData.products.push(currentGroup);
    },
    findGroupInStructure(id) {
      const found = this.currentData.products.find(
        (v) => v.product_id === id && v.type === "product_group"
      );
      if (found) return found;
      return null;
    },
    findProductInStructure(productId, group = undefined, type = "selected") {
      const key = type === "selected" ? "children" : "excluded_children";
      if (group) {
        const res = this._findProductInStructure(group[key], productId, key);
        return res || null;
      } else {
        const found = this._findProductInStructure(
          this.currentData.products,
          productId,
          key
        );
        if (found) return found;
      }
      return null;
    },
    _findProductInStructure(items, productId, key) {
      for (const item of items) {
        if (item.type === "product" && item.product_id === productId) {
          return item;
        } else if (item.type === "product_group") {
          const res = this._findProductInStructure(item[key], productId);
          if (res) return res;
        }
      }
      return null;
    },
  },
  watch: {
    value(v) {
      if (v) {
        this.currentData = cloneDeep(this.productsData[0] || { products: [] });
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-expansion-panel::before) {
  box-shadow: unset !important;
}

:deep(.v-expansion-panel:last-child::before) {
  border-bottom: thin solid;
  border-color: rgba(0, 0, 0, 0.12);
  bottom: -1px;
  height: 1px;
  top: unset;
}
</style>
