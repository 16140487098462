<template>
  <div class="px-1 mt-10">
    <v-row align="center" сlass="ma-0" style="margin-left: 0; margin-right: 0">
      <div class="text-h6 mr-3">Изображения</div>
      <v-divider />
    </v-row>
    <template v-if="item">
      <v-row align="start" class="mt-3" no-gutters :key="key">
        <ImageUploader
          :image="item.image"
          :aspect-ratio="16 / 9"
          class="mr-3"
          width="320"
          height="180"
          title="Основная картинка"
          @input="uploadImage($event, 'image')"
        ></ImageUploader>
        <ImageUploader
          :image="item.first_icon"
          :aspect-ratio="1"
          class="mr-3"
          height="128"
          title="Первая иконка"
          width="128"
          @input="uploadImage($event, 'first_icon')"
        ></ImageUploader>
        <ImageUploader
          :image="item.last_icon"
          :aspect-ratio="1"
          class="mr-3"
          height="128"
          title="Последняя иконка"
          width="128"
          @input="uploadImage($event, 'last_icon')"
        ></ImageUploader>
      </v-row>
    </template>
  </div>
</template>

<script>
import ImageUploader from "@/admin/components/ImageUploader.vue";

export default {
  name: "Reward",
  components: { ImageUploader },
  computed: {},
  props: {
    item: Object,
  },
  emits: ["inited"],
  data: () => {
    return {
      imagesToSetAfterCreateByField: {},
      key: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("inited");
    });
  },
  methods: {
    async uploadImage(file, field) {
      if (!this.item.id) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        this.imagesToSetAfterCreateByField[field] = file;
        reader.onload = () => {
          Object.assign(this.item, { [field]: reader.result });
          this.key++;
        };
      } else {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("type", field);
        const res = await this.$promocodeCondition.sendPutSingle(
          "set_image",
          this.item.id,
          formData
        );
        this.item[field] = res[field];
        this.$store.commit("setSnackbar", {
          color: "success",
          text: "Изображение сохранено",
        });
      }
    },
    afterSave(_) {
      for (const field of Object.keys(this.imagesToSetAfterCreateByField)) {
        void this.uploadImage(this.imagesToSetAfterCreateByField[field], field);
      }
    },
  },
};
</script>

<style scoped></style>
