<template>
  <div class="px-1 mt-10">
    <v-row align="center" сlass="ma-0" style="margin-left: 0; margin-right: 0">
      <div class="text-h6 mr-3">Условие применения промокода</div>
      <v-divider />
    </v-row>
    <template v-if="item">
      <v-text-field
        v-if="item.type === $promocodeCondition.typeEnum.TEXT"
        v-model="item.for_apply.apply_limit"
        label="Лимит применений"
        class="mb-3 mt-5 rounded-lg"
        dense
        hide-details
        outlined
        type="number"
        :disabled="disabled"
      ></v-text-field>
      <v-text-field
        v-model="item.for_apply.min_order_sum"
        label="Минимальная сумма заказа в рублях"
        class="mb-3 mt-5 rounded-lg"
        dense
        hide-details
        outlined
        type="number"
        :disabled="disabled"
      ></v-text-field>
      <v-select
        v-model="item.for_apply.applicable_order_types"
        :items="$order.pickupTypes"
        item-value="value"
        item-text="label"
        class="mt-3 rounded-lg"
        clearable
        dense
        hide-details
        outlined
        label="Тип заказа"
        multiple
        :disabled="disabled"
      ></v-select>
      <v-checkbox
        v-model="item.for_apply.for_first_order"
        label="Первый заказ"
        hide-details
        :disabled="disabled"
      />
      <v-btn depressed @click="productsSelectorDialog = true" class="mt-5"
        >Выбрать продукты
      </v-btn>
      <!--      {{ item?.for_apply }}-->
      <ProductsSelector
        v-model="productsSelectorDialog"
        @updateProductsData="updateProductsStructure($event)"
        :products-data="[{ products: item?.for_apply?.products_data || [] }]"
        :menu="productsStructure"
        :disabled="disabled"
      />
    </template>
  </div>
</template>

<script>
import rules from "air-vue-model/rules";
import ProductsSelector from "@/admin/views/promocodes/coditionComoponents/ProductsSelector.vue";

export default {
  name: "Reward",
  components: { ProductsSelector },
  computed: {
    rules() {
      return rules;
    },
  },
  props: {
    item: Object,
    disabled: Boolean,
  },
  data: () => {
    return {
      productsSelectorDialog: false,
      menuStructure: [],
      productsStructure: [],
    };
  },
  mounted() {
    void this.$product // todo вынести на уровень выше, чтобы не было двух запросов
      .sendGetSingle("structure", undefined, {
        active: true,
        show_in_app: true,
      })
      .then((res) => {
        this.productsStructure = res.result;
      });
  },
  methods: {
    updateProductsStructure(data) {
      const item = data[0] || { products: [] };
      if (!this.item.for_apply) this.item.for_apply = { products_data: [] };
      this.item.for_apply.products_data = item.products || [];
    },
  },
};
</script>

<style scoped></style>
