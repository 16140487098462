const { cloneDeep } = require("lodash");
const lightBox = require("@/components/helpers/LightBox.vue");
const m = require("air-vue-model")();
m.url = "user_promocodes";
m.name = "userPromocode";
// m.text = {
//   itemTitle: "Промокод",
//   listTitle: "Список промокодов",
// };

// m.pagination.page_size = 'all'

m.filters = {
  promocode_condition: null,
};

m.typeValues = { // todo удалить похожие символы
  1: "abcdefghjkmnpqrstuvwxyz",
  2: "ABCDEFGHJKMNPQRSTUVWXYZ",
  3: "абвгдеёжзиклмнопрстуфхцчшщъыьэюя",
  4: "АБВГДЕЁЖЗИКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ",
  5: "23456789",
};

m.typeName = {
  1: "Латиница строчные",
  2: "Латиница заглавные",
  3: "Кириллица строчне",
  4: "Кириллица заглавные",
  5: "Цифры",
};

m.types = Object.keys(m.typeName).map(_key => {
  const key = Number(_key)
  return {
    label: m.typeName[key],
    value: key
  }
})

m.getAvailableCombinationsCount = (types, length) => {
  let chars = ''
  for (const type of types) {
    chars += m.typeValues[type]
  }
  return chars.length ** length
}

module.exports = m;
