<template>
<!--  :show-create-button="$auth.hasRight('roles__edit_information')"-->
  <template-list :model="$promocodeCondition" >
    <template v-slot="{object, to, index}">
      <v-col class="pa-0 body-2 pointer">
        <router-link :to="to" style="width: 100%; height: 100%">
          <v-row no-gutters align="start link">
            <v-col class="font-weight-bold">
              {{ object.name }}
            </v-col>
          </v-row>
        </router-link>
      </v-col>
    </template>
  </template-list>
</template>

<script>
export default {
  name: 'PromocodeConditionsList',
  components: {},
  data: () => ({}),
  methods: {},
  created() {
    this.$promocodeCondition.loadList()
  }
}
</script>
